<template>
  <v-container fluid class="pa-0 ma-0">

    <!-- <v-toolbar dense flat color="grey lighten-2">
      <v-btn text color="primary" @click="editFirm">
        수정
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text icon>
        <v-icon small>apps</v-icon>
      </v-btn>
    </v-toolbar> -->

    <v-card
      tile
      :elevation="0"
    >
      <v-row
        align="center"
        justify="center"
        no-gutters
        class="ma-0 pa-5"
      >
        <v-col cols="12" class="hidden-xs-only">
          <v-alert
            text
            dense
            outlined
            color="info"
            icon="info"
          >
            <div class="text-body-2">
              관리자와 관리대행자는 회의실을 생성 및 삭제할 수 있습니다.
            </div>
            <!-- <div class="text-body-2">
              업무부서가 삭제되면 하위의 모든 정보도 같이 소멸되어, 복구할 수 없습니다.
            </div> -->
          </v-alert>
        </v-col>
        <v-col cols="12">

          <!-- content area -->
          <v-row
            align="center"
            justify="start"
            class="pl-12 pr-12"
          >
            <template v-for="cabinet in cabinets">
              <v-col
                :Key="cabinet.id"
                cols="12" lg="3" md="3" sm="6" xs="12"
              >
                <v-card
                  outlined
                  elevation="0"
                  class="pt-1"
                  color="primary"
                  max-width="230"
                >
                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                    <v-card
                      min-height="210"
                      :elevation="hover ? '12' : '0'"
                    >
                      <v-card-title>
                        <span
                          :class="hover ? 'text-subtitle-1 font-weight-bold' : 'text-subtitle-2'"
                        >
                          {{ cabinet.name }}
                        </span>
                        <v-spacer></v-spacer>
                        <!-- <v-tooltip
                          eager
                          bottom
                          color="primary"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="primary"
                              class="mx-1 mb-1"
                              @click.stop.prevent="editCabinet(cabinet)"
                            >
                              <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>수정</span>
                        </v-tooltip> -->
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text
                        @click.stop="editCabinet(cabinet)"
                        class="text-body-2 text-left"
                        style="cursor: pointer"
                      >
                        <div
                          v-show="cabinet.capacity"
                          class="mb-2"
                          style="font-size: 0.925rem;font-weight: 400;"
                        >
                          <span class="text-body-2 grey--text ml-1 mr-1">• </span>
                          {{ cutString(cabinet.capacity, 24) }}
                        </div>
                        <div
                          v-show="cabinet.equips"
                          class="mb-2"
                          style="font-size: 0.925rem;font-weight: 400;"
                        >
                          <span class="text-body-2 grey--text ml-1 mr-1">• </span>
                          {{ cutString(cabinet.equips, 24) }}
                        </div>
                        <div
                          v-show="cabinet.remark"
                          style="font-size: 0.925rem;font-weight: 400;"
                        >
                          <span class="text-body-2 grey--text ml-1 mr-1">• </span>
                          {{ cutString(cabinet.remark, 24) }}
                        </div>
                      </v-card-text>
                      <v-card-text class="text-center ma-0 pa-0">
                        <div v-show="hover">
                          <v-tooltip
                            bottom
                            color="primary"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                small
                                color="primary"
                                class="mx-1 mb-1"
                                @click.stop.prevent="editCabinet(cabinet)"
                              >
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>수정</span>
                          </v-tooltip>
                          <!-- <v-tooltip
                            bottom
                            color="primary"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                small
                                color="primary"
                                class="mx-1 mb-1"
                                @click.stop="deleteCabinet(cabinet.id)"
                              >
                                <v-icon small>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>삭제</span>
                          </v-tooltip> -->
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-card>
              </v-col>
            </template>

            <!-- add 버튼 영역 -->
            <v-col cols="12" lg="3" md="3" sm="6" xs="12">
              <v-card
                outlined
                elevation="0"
                class="pt-0"
                max-width="230"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    min-height="210"
                    :elevation="hover ? '5' : '0'"
                  >
                    <v-card-text class="text-center">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            v-on="on"
                            :color="hover ? 'grey lighten-1' : ''"
                            class="mt-5" size="120"
                          >
                            <v-btn icon
                              @click="addCabinet"
                            >
                              <v-icon
                                :color="hover ? 'white' : 'grey'"
                                size="120"
                              >mdi-plus</v-icon>
                            </v-btn>
                          </v-avatar>
                        </template>
                        <span>추가</span>
                      </v-tooltip>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-card>
            </v-col>
            <!--/ add 버튼 영역 -->

          </v-row>
          <!--/content area -->

        </v-col>
      </v-row>
    </v-card>

    <!-- !! overlay -->
    <v-overlay :value="overlay" opacity="0.25">
      <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
    </v-overlay>

    <add-cabinet-dialog ref="addCabinetDialog"></add-cabinet-dialog>
    <edit-cabinet-dialog ref="editCabinetDialog" :cabinetInfo="cabinetInfo"></edit-cabinet-dialog>

  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'

// @: filters
import cutString from '@/filters/cutString'

import addCabinetDialog from '@/components/admin/addCabinetDialog'
import editCabinetDialog from '@/components/admin/editCabinetDialog'

export default {
  components: {
    addCabinetDialog,
    editCabinetDialog
  },

  data: () => ({
    cabinets: [],
    // 참고: 회의실 수정시 - 기본적으로 회의실 정보 편집 창으로 넘길 정보
    cabinetInfo: {
      id: 0,
      name: '',
      color: '',
      type: 'M',
      caption: ''
    },
    // 구분: 오버레이용 변수
    overlay: false
  }),

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    // 리스트 가져오기
    this.initialize()
  },

  methods: {
    cutString,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // * 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기에 리스트 가져오기
    async initialize () {
      await this.list()
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 참고: 회의실 리스트 가져오기
    async list () {
      try {
        this.overlay = true

        // 중요: 삭제시 잔상이 남기 때문에 cabinets 배열을 확실히 비우고 다시 패칭해야 한다.
        this.cabinets = []

        // 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(300 - Math.floor(Math.random() * 200))

        const { data } = await this.$axios.get('lawork/lwc/cabinetList')
        if (!data.success) throw new Error(`list error: ${data.message}`)
        this.cabinets = data.list

        this.overlay = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 회의실 추가 팝업 열기
    async addCabinet () {
      try {
        if (await this.$refs.addCabinetDialog.open('회의실', { width: 570 })) {
          this.list() // refresh
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 회의실 편집 팝업 열기
    async editCabinet (cabinet) {
      try {
        // console.log(cabinet)
        this.cabinetInfo = Object.assign(this.cabinetInfo, cabinet) // 참고: 혹시나 해서 회의실 정보를 한번 더 넘겨준다
        if (await this.$refs.editCabinetDialog.open('회의실', { width: 570 }, cabinet)) {
          this.list() // refresh
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
    // 사용안함: 회의실 삭제
    // async deleteCabinet (cabinetId) {
    //   try {
    //     // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
    //     const target = 'confirmDialog'
    //     const pop = await this.findParentRefs(this.$parent, target)
    //     if (!pop) throw new Error('팝업창을 열 수 없습니다.')
    //     // 찾았으면 팝업을 연다
    //     const msg = `업무부서와 해당 정보를 삭제하며, 다시 복구할 수 없습니다.이관할 정보가 있는지 확인하기 바랍니다.`
    //     if (await pop.open('삭제', msg, { color: 'error', width: 380 })) {
    //       const { data } = await this.$axios.get(`lawork/lwc/deleteCabinet/${cabinetId}`)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //       this.list() // refresh
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // }
  }
}
</script>

<style>
/*
  참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
