<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12" sm="10">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width: 250px;">
                  <v-text-field
                    ref="txtCabinetName"
                    v-model.trim="form.name"
                    v-validate="'required|max:10'"
                    :error-messages="errors.collect('name')"
                    data-vv-name="name"
                    required
                    label="회의실명"
                    counter="10"
                    maxlength="10"
                    class="pr-3"
                    @keypress.enter="agree"
                    :error="isOverlabed"
                  ></v-text-field>
                </div>
                <!-- // 중복체크 버튼 -->
                <v-btn
                  small
                  text
                  class="ma-2"
                  :color="mustChk ? 'error' : 'indigo'"
                  @click="chkCabinetNameOverlap"
                >
                  <v-icon small class="text-left">
                    {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  중복체크
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="2" class="text-center">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    class="pb-1"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>{{ form.caption }}</span>
              </v-tooltip> -->
            </v-col>
            <!-- <v-col cols="12" xs="12">
              <v-divider class="my-2"></v-divider>
            </v-col> -->
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.capacity"
                maxlength="20"
                label="수용인원"
                hint="예) 20명"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.equips"
                maxlength="40"
                label="보유장비"
                hint="예) 컴퓨터,빔프로젝터"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txtRemark"
                v-model.trim="form.remark"
                label="특이사항"
                outlined
                no-resize
                rows="3"
                counter="100"
                :rules="rules"
                class="my-5"
                @update:error="etcError"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

// @: filters
import isSpKey from '@/filters/isSpecialCharacter'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      name: '', // ? 회의실명
      capacity: '', // ? 수용인원
      equips: '', // ? 보유장비
      remark: '', // ? 특이사항
      status: 1, // * 1(사용가능)/0(시용불가)
      isStar: 0,
      wemail: '',
      wname: ''
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        name: '회의실명'
      }
    },
    // 중요: 텍스트박스의 글자수 체크 변수 - rules[0]() 함수는 true 나 문자열을 반환한다.
    rules: [ v => v.length <= 100 || '100자 이하의 내용을 입력해 주세요' ],
    chkValidate: true, // 폼 유효성 - 기타 셀렉트 100자 이상이면 false
    mustChk: false, // 중복체크를 해야 하는지 여부
    chkMulti: false, // 참고: 팀명 중복 확인했는지 여부
    isOverlabed: false // 참고: 팀명이 중복 되었는지 여부
  }),

  watch: {
    'form.name': { // * 회의실명 자동감지 - 특수문자만 필터링
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          if (isSpKey(val)) {
            this.form.name = oldVal
            this.$refs.txtCabinetName.lazyValue = oldVal
          }
          // 중복 체크 관련 초기화
          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false
        }
      }
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    isSpKey, // 특수문자 필터링
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화 함수
    initDialog () {
      // form validation 초기화
      this.$validator.reset()

      this.form.id = 0

      this.form.id = 0
      this.form.name = '' // ? 회의실명
      this.form.capacity = '' // ? 수용인원
      this.form.equips = '' // ? 보유장비
      this.form.remark = '' // ? 특이사항
      this.form.status = 1 // ! 1(사용가능)/0(시용불가)
      this.form.isStar = 0

      this.form.wemail = this.$store.state.ui.email || ''
      this.form.wname = this.$store.state.ui.name || ''

      this.mustChk = false
      this.chkMulti = false
      this.isOverlabed = false
    },
    open (title, options, cabinet) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        this.form.id = cabinet.id

        this.form.name = cabinet.name // 회의실명
        this.form.capacity = cabinet.capacity // 수용인원
        this.form.equips = cabinet.equips // 보유장비
        this.form.remark = cabinet.remark // 특이사항
        this.form.status = cabinet.status || 1 // 1(사용가능)/0(시용불가)

        this.form.wemail = this.$store.state.ui.email || cabinet.wemail
        this.form.wname = this.$store.state.ui.name || cabinet.wname

        this.$nextTick(function () {
          this.mustChk = false // 중복체크 필요없는걸로
          this.chkMulti = true // 이미 중복체크 한걸로
          this.isOverlabed = false // 중복되지 않은걸로
        })

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 등록 버튼
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: textarea 의 글자수가 100 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules[0](this.$refs.txtRemark.value) === true) {
          this.chkValidate = true
        }

        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 특이사항 항목의 글자수를 줄여주세요.')

        // 회의실명 중복체크 확인
        if (this.mustChk && !this.chkMulti) {
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtCabinetName.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        const { data } = await this.$axios.post('lawork/lwc/editCabinetRoom', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 삭제 버튼
    async remove () {
      // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
      const target = 'confirmDialog'
      const pop = await this.findParentRefs(this.$parent, target)
      if (!pop) throw new Error('팝업창을 열 수 없습니다.')
      // 찾았으면 팝업을 연다
      const msg = '<br>회의실을 삭제하면 예약된 회의도 함께 삭제됩니다.<br>계속 하시겠습니까?'
      if (await pop.open('삭제', msg, { color: 'error', width: 380 })) {
        const { data } = await this.$axios.get(`lawork/lwc/deleteCabinetRoom/${this.form.id}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)

        this.initDialog()
        this.resolve(true)
        this.dialog = false
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 구분: 회의실명 중복체크 함수
    async chkCabinetNameOverlap () {
      try {
        if (this.form.name.length > 0) {
          const { data } = await this.$axios.get(`admin/firm/chkCabinetNameOverlap/${this.form.name}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) { // 중복 되었다
            this.mustChk = true
            this.chkMulti = false
            this.isOverlabed = true
            this.$refs.txtCabinetName.focus()
            throw new Error(`이미 등록된 회의실명 입니다.`)
          } else {
            this.mustChk = false
            this.chkMulti = true
            this.isOverlabed = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 회의실명입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 기타 텍스트 에어리어 에러 처리 이벤트 핸들러
    etcError () {
      this.chkValidate = false
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
